var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-col',{attrs:{"cols":"12","md":_vm.size === 2
      ? 6
      : _vm.size === 2
      ? 6
      : _vm.size === 3
      ? 4
      : _vm.size === 4
      ? 3
      : undefined}},[_c('base-card',{attrs:{"data-aos":"fade-up","color":"rgba(203, 170, 92, 0.10)","href":'video/' + _vm.value.id,"target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',{staticStyle:{"overflow":"hidden"}},[_c('v-img',{ref:'img' + _vm.value.video_id,class:{
            'img-default-size': true,
            'img-rotate-size': hover,
          },attrs:{"src":'https://img.youtube.com/vi/' +
            _vm.value.video_id +
            '/maxresdefault.jpg',"lazy-src":"https://picsum.photos/10/6?image=15","gradient":hover
              ? 'rgba(0, 0, 0, .15),rgba(0, 0, 0, .15)'
              : 'rgba(0, 0, 0, .30),rgba(0, 0, 0, .30)',"aspect-ratio":"1.7"}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-scroll-y-reverse-transition',[(!hover)?_c('v-btn',{attrs:{"small":"","depressed":"","fab":"","color":'rgba(203, 170, 92, 0.51)',"target":"_blank"}},[(!hover)?_c('v-icon',{attrs:{"color":'rgba(255, 255, 255, 0.90)',"size":"30"}},[_vm._v(" mdi-play ")]):_vm._e()],1):_vm._e()],1)],1)],1)],1)]}}],null,false,4180740416)}),_c('v-card-text',{attrs:{"color":"grey"}},[_c('h3',{staticClass:"font-weight-bold mb-2 text-truncate"},[_vm._v(" "+_vm._s(_vm.value.title)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.value.date,"dddd, MMMM Do YYYY"))+" ")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }